<template>
  <div class="c-container c-container--small">
    <Tile class="mb-10">
      <!-- Thumbnail -->
      <div
        class="flex object-cover justify-center items-center w-full bg-center bg-no-repeat bg-cover rounded"
        style="aspect-ratio: 2/1"
        :style="`background: ${
          campaign && campaign.thumbnail
            ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${campaign.thumbnail}')`
            : 'rgba(0, 0, 0, 0.2)'
        }`"
      >
        <label class="input__file">
          <Icon icon="add-image" class="mb-4" :width="32" :height="32" />

          <span class="text-xl text-white">
            {{ campaign && campaign.thumbnail ? "Change thumbnail" : "Click to add thumbnail" }}
          </span>
          <input ref="imageInput" type="file" accept="image/*" class="w-full h-full" @change="uploadThumbnail" />
        </label>
      </div>

      <!-- Campaign name -->
      <div class="flex p-8 w-full border-bottom--base">
        <InputText ref="input" label="Campaign name" :required="true" :value="campaign.title" @onChange="setTitle" />
      </div>

      <!-- Campaign description -->
      <div class="flex p-8 w-full border-bottom--base">
        <InputTextarea
          ref="input"
          label="Campaign Description"
          :value="campaign.info.description"
          height="5rem"
          resize="vertical"
          @onChange="setDescription"
        />
      </div>

      <!-- Campaign type -->
      <div v-if="checkIfAdminOrg" class="flex flex-col p-8 border-bottom--base">
        <div class="flex items-center w-full">
          <Title size="small" title="Campaign type" class="text-blue-lighter" />

          <InputToggle
            class="ml-auto"
            :label="campaign.public ? 'Public' : 'Private'"
            :checked="campaign.public"
            :value="campaign.public"
            @toggle="toggleCampaignType"
          />
        </div>
      </div>

      <!-- Allowed languages -->
      <div v-if="checkIfAdminOrg" class="flex flex-col p-8 border-bottom--base">
        <MultiSelect
          :items="languageList"
          label="Broadcasting languages"
          :required="true"
          @selectedItems="setBroadcastingLanguages"
        />
      </div>

      <!-- Campaign price rate -->
      <div v-if="checkIfAdminOrg" class="flex flex-col gap-4 p-8 border-bottom--base">
        <Title title="Price rate (CPM in EUR)" size="small" class="text-blue-lighter" />

        <InputText
          placeholder="Insert amount"
          :border="true"
          align="right"
          :width="200"
          class="shrink"
          type="number"
          :value="campaign.info.priceRate"
          @onChange="setPriceRate"
        />
      </div>

      <!-- Campaign payable date -->
      <div v-if="checkIfAdminOrg" class="flex flex-col gap-4 p-8 border-bottom--base">
        <Title title="Payable date" size="small" class="text-blue-lighter" />

        <p v-if="campaign.info.payableDate">
          {{
            new Date(campaign.info.payableDate).toLocaleDateString(undefined, {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          }}
        </p>

        <p v-else>No payment date selected</p>

        <DatePicker v-model="campaign.info.payableDate" :is-dark="true" />
      </div>

      <!-- Display frequency -->
      <div class="flex flex-col p-8 border-bottom--base">
        <div class="flex items-center mb-4 w-full">
          <Title size="small" title="Display frequency" class="text-blue-lighter" />

          <span class="ml-auto text-sm text-signal-red">Required</span>
        </div>

        <div class="flex items-center w-full">
          <p class="paragraph">Minimum time between ad</p>

          <div class="flex">
            <InputText
              placeholder="X"
              :border="true"
              :width="40"
              align="center"
              :value="campaign.displayFreq"
              class="ml-4 shrink"
              type="number"
              :min="1"
              :max="999"
              @onChange="setDisplayFreqValue"
            />

            <Dropdown
              :border="true"
              class="ml-2"
              :items="[
                {
                  label: 'Minutes',
                },
                {
                  label: 'Hours',
                },
              ]"
              @change="setDisplayFreqUnit"
            />
          </div>
        </div>
      </div>

      <!-- Brand -->
      <div class="flex flex-col p-8 border-bottom--base">
        <div class="flex items-center mb-4 w-full">
          <Title size="small" title="Brand" class="text-blue-lighter" />

          <Button class="mr-2 ml-auto text-sm" kind="link" label="Create new brand" @clicked="createNewBrand" />

          <span class="text-sm text-signal-red">Required</span>
        </div>

        <div class="flex items-center w-full">
          <select v-model="campaign.brand" class="input__select" @change="setCampaignBrand">
            <option v-for="brand of getBrands" :key="brand._id" :value="{ id: brand._id, title: brand.title }">
              {{ brand.title }}
            </option>
          </select>
        </div>
      </div>

      <!-- Campaign targets -->
      <div class="flex flex-col p-8 border-bottom--base">
        <div class="flex items-center w-full">
          <Title size="small" title="Campaign targets" class="text-blue-lighter" />

          <InputToggle class="ml-auto" label="Enable campaign targets" @toggle="toggleMilestones" />
        </div>

        <div v-if="showMilestones" class="flex flex-col mt-6">
          <span class="mb-6 paragraph"> End campaign when following targets have been reached </span>

          <div v-for="(item, i) in campaign.targets" :key="i" class="flex items-center mb-10 w-full">
            <InputText
              v-if="item.unit.id === 'views'"
              placeholder="Insert amount"
              :index="i"
              :border="true"
              align="right"
              :width="200"
              class="shrink"
              type="number"
              :value="item.value"
              @onChange="setMilestoneValue"
            />

            <Calendar
              v-else-if="item.unit.id === 'time'"
              :min-width="200"
              :index="i"
              :start="item.startTime"
              :end="item.endTime"
              @toggle="setMilestoneDate"
            />

            <Dropdown
              :border="true"
              class="ml-2"
              :index="i"
              :items="[
                {
                  id: 'views',
                  label: 'Ad views',
                },
                {
                  id: 'time',
                  label: 'Date',
                },
              ]"
              :current-item="item.unit.id"
              @change="setMilestoneUnit"
            />

            <Button class="ml-auto" :index="i" kind="link" label="Remove" @clicked="removeMilestone" />
          </div>

          <div class="flex w-full">
            <Button kind="secondary" label="Add another target" @clicked="addMilestone" />
          </div>
        </div>

        <span v-else class="mt-2 paragraph"> Campaign targets not enabled </span>
      </div>
    </Tile>

    <div class="flex justify-center w-full">
      <Button kind="primary" @clicked="nextStep"> Next step </Button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { Button, Icon, Title } from "cavea-design-system";
import { DatePicker } from "v-calendar";
import log from "@/lib/log";

export default {
  name: "CreateCampaignSettings",

  metaInfo: {
    title: "Create Campaign Settings",
  },

  components: {
    Title,
    Tile: () => import("@/components/Tile"),
    InputText: () => import("@/components/inputs/InputText"),
    Dropdown: () => import("@/components/Dropdown"),
    Button,
    InputToggle: () => import("@/components/inputs/InputToggle"),
    Calendar: () => import("@/components/Calendar"),
    InputTextarea: () => import("@/components/inputs/InputTextarea"),
    MultiSelect: () => import("@/components/inputs/MultiSelect"),
    Icon,
    DatePicker,
  },

  data() {
    return {
      campaign: null,
      showMilestones: false,
      displayFreq: {
        unit: "minutes",
        value: 0,
      },
      languageList: [],
    };
  },

  computed: {
    ...mapGetters(["getCampaign", "getBrands", "getOrganization"]),

    getDisplayFreq() {
      if (this.displayFreq.unit === "hours") {
        return parseInt(this.displayFreq.value, 10) * 60;
      }
      return parseInt(this.displayFreq.value, 10);
    },

    checkIfAdminOrg() {
      return this.getOrganization?._id === "6104316858d6180c099272b9";
    },
  },

  watch: {
    campaign: {
      deep: true,
      handler() {
        this.setCampaign(this.campaign);
      },
    },

    displayFreq: {
      deep: true,

      handler() {
        this.campaign.displayFreq = this.getDisplayFreq;
      },
    },
  },

  created() {
    this.campaign = this.getCampaign;
    if (!this.campaign.brand) {
      this.campaign.brand = {
        id: "",
        title: "",
      };
    }

    if (!this.campaign.info) {
      this.campaign.info = {
        description: "",
        priceRate: null,
        payableDate: null,
      };
    }

    if (!this.campaign.info.payableDate) this.campaign.info.payableDate = null;

    if (this.campaign.targets.length) this.showMilestones = true;

    this.displayFreq.value = this.campaign.displayFreq;

    this.fetchLanguageList();
  },

  methods: {
    ...mapMutations(["setCampaign"]),

    ...mapActions(["fetchUserOrganizations"]),

    /**
     * @summary toggle milestones on/off
     * @param {boolean} value
     */
    toggleMilestones(value) {
      this.showMilestones = value;
      if (value) {
        const newTarget = {
          value: 0,
          unit: {
            id: "views",
            label: "Ad views",
          },
        };
        this.campaign.targets.push(newTarget);
      } else {
        this.campaign.targets = [];
      }
    },

    /**
     * @summary add new milesone
     */
    addMilestone() {
      const newTarget = {
        value: 0,
        unit: {
          id: "views",
          label: "Ad views",
        },
      };
      this.campaign.targets.push(newTarget);
    },

    /**
     * @summary remove milestone
     * @param { { index: number, value: string|number } } obj
     */
    removeMilestone(obj) {
      this.campaign.targets.splice(obj.index, 1);
    },

    /**
     * @summary set milestone value
     * @param {number} value
     * @param {number} index
     */
    setMilestoneValue(value, index) {
      this.campaign.targets[index].value = parseInt(value, 10);
    },

    /**
     * @summary set milestone date
     * @param {{item}} value
     */
    setMilestoneDate(value) {
      this.campaign.targets[value.item.index].startTime = new Date(value.item.start);
      this.campaign.targets[value.item.index].endTime = new Date(value.item.end);
    },

    /**
     * @summary set milestone unit
     * @param { { prop: any, index: number, item: { id: string, label: string } } } value
     */
    setMilestoneUnit(obj) {
      this.campaign.targets[obj.index].unit = obj.item;

      if (obj.item.id === "time") {
        delete this.campaign.targets[obj.index].value;
        this.campaign.targets[obj.index] = {
          startTime: null,
          endTime: null,
          unit: {
            id: obj.item.id,
            label: obj.item.label,
          },
        };
      } else if (obj.item.id === "views") {
        this.campaign.targets[obj.index] = {
          value: null,
          unit: {
            id: obj.item.id,
            label: obj.item.label,
          },
        };
      }
    },

    /**
     * @summary set title of AdCampaign
     * @param {string} value
     */
    setTitle(value) {
      this.campaign.title = value;
    },

    /**
     * @param {number} value
     */
    setDisplayFreqValue(value) {
      this.displayFreq.value = value;
    },

    /**
     * @summary set display frequency unit
     * @param { { prop: any, index: number, item: { id: string, label: string } } } value
     */
    setDisplayFreqUnit(value) {
      this.displayFreq.unit = value.item.label.toLowerCase();
    },

    /**
     * @summary go to next page
     */
    nextStep() {
      if (
        this.campaign?.title &&
        this.campaign?.displayFreq &&
        this.campaign?.brand?.id &&
        this.campaign?.brand?.title
      ) {
        this.$router.push("/create/campaign/streamers");
      }
    },

    /**
     * @summary set the active campaign brand & update existing overlay images
     */
    setCampaignBrand() {
      // Set overlay images brand to the selected brand
      if (
        this.campaign.brand.id &&
        this.campaign.tools.overlay &&
        this.campaign.tools.overlay.carousel.imageArr.length
      ) {
        for (let i = 0; i < this.campaign.tools.overlay.carousel.imageArr.length; ++i) {
          this.campaign.tools.overlay.carousel.imageArr[i].brandId = this.campaign.brand.id;
          this.campaign.tools.overlay.carousel.imageArr[i].title = this.campaign.brand.title;
        }
      }

      this.setCampaign(this.campaign);
    },

    /**
     * @summary set campaign description
     * @param {number|string} value
     */
    setDescription(value) {
      this.campaign.info.description = value;
    },

    /**
     * @summary convert value to number and set it as pricerate
     * @param {number|string} value
     */
    setPriceRate(value) {
      this.campaign.info.priceRate = parseFloat(value);
    },

    async fetchLanguageList() {
      log("fetchLanguageList");

      if (this.campaign?.info?.broadcastingLanguages?.length > 0) {
        log("Campaign has a language list");

        this.languageList = this.campaign.info.broadcastingLanguages;
      } else {
        log("Campaign doesn't have a language list - fetching one now");

        const HOST = process.env.VUE_APP_API_URL;
        const URL = `${HOST}/get-languages`;

        const languages = await axios
          .get(URL)
          .then((res) => res.data.languages)
          .catch((err) => {
            log("error getting languages", err);
            return [];
          });

        if (languages.length) {
          this.languageList = languages.map((lang) => ({
            checked: false,
            value: lang.toLowerCase(),
          }));
        } else {
          this.languageList = [];
        }
      }
    },

    setBroadcastingLanguages(languages) {
      log("setBroadcastingLanguages");
      log(
        "checked",
        languages.filter((l) => l.checked)
      );

      this.campaign.info.broadcastingLanguages = languages;
    },

    toggleCampaignType(e) {
      this.campaign.public = e;
    },

    async createNewBrand() {
      log("createNewBrand");
      const HOST = process.env.VUE_APP_API_URL;

      await this.$swal
        .fire({
          title: "Please input title of brand",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Create",
          showLoaderOnConfirm: true,
          preConfirm: async (brandTitle) =>
            await axios
              .post(`${HOST}/brand/create`, {
                title: brandTitle,
                orgId: this.getOrganization._id,
              })
              .then((res) => res.data)
              .catch((error) => {
                this.$swal.showValidationMessage(
                  `${error?.response?.data?.msg || "Something went wrong, please try again later"}`
                );
              }),
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.fetchUserOrganizations();

            this.$swal.fire({
              icon: "success",
              timer: 2000,
              title: `New brand ${`${result?.value?.brand?.title} ` || ""}created successfully.`,
            });
          }
        });
    },

    /**
     * @param {string} url
     */
    setCampaignThumbnail(url) {
      log({ fn: "setCampaignThumbnail", url });
      this.campaign.thumbnail = url;
    },

    async uploadThumbnail() {
      log("uploadThumbnail");

      const input = this.$refs?.imageInput;

      if (input) {
        const formData = new FormData();
        window.URL = window?.URL || window?.webkitURL;

        formData.append("file", input?.files[0]);
        const headers = { "Content-Type": "multipart/form-data" };
        await axios
          .post(`${process.env.VUE_APP_STORAGE_SERVICE}/upload-binary`, formData, { headers })
          .then((res) => res?.data && this.setCampaignThumbnail(res?.data))
          .catch((e) => {
            console.error("uploadThumbnail e", e);
            this.$swal({
              icon: "error",
              title: "Error uploading thumbnail",
              showConfirmButton: false,
              timer: 1000,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/colors";
@import "../../../styles/fonts";
@import "../../../styles/globals";

.input__select {
  outline: 0;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0.5rem;
  height: 2.5rem;
  background: transparent;
  border-radius: $border-radius;
  transition: var(--transition-out);
  background-color: color(white, off);
  font-family: $primary-font-stack;
  border: 1px solid color(border, base);
  color: color(blue, light);
  option {
    color: inherit;
  }
}

.input__file {
  align-items: center;
  height: 100%;
  margin-left: 0 !important;
  border-radius: $border-radius;
  color: color(blue, lighter);
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  width: 100% !important;
  object-fit: cover;
  display: flex;

  > img {
    width: 100%;
    height: 100%;
  }

  > input {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }
}
</style>
